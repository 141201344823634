
import { Component, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import PromotionsHintTooltip from './promotions-hint-tooltip.vue';
import HotelSearchbar from '../_common/hotel-searchbar.vue';

@Component({
    components: {
        DateDocumentFilter,
        PromotionsHintTooltip,
        HotelSearchbar,
    },
})
export default class PromotionsHeader extends Vue {}
