
import { Vue, Component } from 'vue-property-decorator';
import CustomTooltip, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class PromotionsHintTooltip extends Vue {
    hint: HTMLElement | null = null;
    hintBoundings = {
        x: 0,
        y: 70,
    };

    tooltipDirection = TOOLTIP_DIRECTION.RIGHT;

    handleHover(e: MouseEvent) {
        this.hint = e.type === 'mouseover'
            ? e.currentTarget as HTMLElement
            : null;
    }
}
