
import { inject } from '@/inversify';
import { Component, Prop } from 'vue-property-decorator';

import type Day from '@/modules/common/types/day.type';
import CalendarChain from '@/modules/common/components/ui-kit/calendar-chain.vue';
import { ASSESSMENT_COLORS } from '@/modules/common/constants';
import ClusterPromotionsService, { ClusterPromotionsServiceS } from '../../cluster-promotions.service';
import ClusterPromotionsModel from '../../models/cluster-promotions.model';

@Component({
    extends: CalendarChain,
})
export default class PromotionsCalendarLine extends CalendarChain {
    @Prop({ type: Boolean })
    public isVisible!: boolean;

    @Prop({
        type: Object,
        default: null,
    })
    private hotelData!: ClusterPromotionsModel;

    @inject(ClusterPromotionsServiceS)
    private clusterPromotionsService!: ClusterPromotionsService;

    get calculatedAssestments() {
        return this.documentFiltersService.days.map(day => ({
            isPastDay: this.documentFiltersService.isPreviousDay(day),
            isNA: false,
            colorModificator: this.getDayColor(day),
        }));
    }

    private getDayColor(day: Day): string {
        const { hotelData } = this;

        const assessment = this.clusterPromotionsService
            .getAssessment(day, hotelData.hotelId);

        return ASSESSMENT_COLORS[assessment];
    }
}
