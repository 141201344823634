
import { ASSESSMENT_TYPES } from '@/modules/common/constants';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import PromotionsService, { PromotionsServiceS } from '@/modules/promotions/promotions.service';
import { inject } from '@/inversify';
import { Vue, Component, Prop } from 'vue-property-decorator';
import PAGES from '@/modules/common/constants/pages.constant';
import ClusterPromotionsService, { ClusterPromotionsServiceS } from '../../cluster-promotions.service';
import ClusterPromotionsModel from '../../models/cluster-promotions.model';
import ClusterService, { ClusterServiceS } from '../../cluster.service';
import PromotionsCalendarLine from './promotions-calendar-line.vue';

@Component({
    components: {
        PromotionsCalendarLine,
    },
})
export default class PromotionsDaysSet extends Vue {
    @inject(ClusterPromotionsServiceS) private clusterPromotionsService!: ClusterPromotionsService;
    @inject(PromotionsServiceS) private promotionsService!: PromotionsService;
    @inject(HelperServiceS) helperService!: HelperService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;

    @Prop({ type: Object, default: () => ({}) })
    hotelData!: ClusterPromotionsModel;

    isCalendarLoading = false;

    beforeMount() {
        this.registerCompset();
    }

    get currentProvider() {
        return this.clusterPromotionsService.settings.provider;
    }

    get compset() {
        if (!this.hotelData.compsetMain) {
            return null;
        }

        return this.clusterService
            .getCompset(
                this.hotelData.compsetMain.id!,
                this.hotelData.hotelId,
            )!;
    }

    get isDocumentEmpty() {
        const { hotelId, compsetMain: doc } = this.hotelData;
        const { programView: program, provider } = this.clusterPromotionsService.settings;

        if (!provider || !doc || this.promotionsService.isDocumentEmpty(doc)) {
            return true;
        }

        return !this.documentFiltersService.days
            .some(day => this.promotionsService
                .getAssessment(day, hotelId, program, provider, doc) !== ASSESSMENT_TYPES.NO_DATA);
    }

    get isLoading() {
        return this.isCalendarLoading || !this.hotelData.documentLoaded;
    }

    // Lazy load of document, send request only if component is in viewport.
    async loadViewportedHotel(isVisible: boolean) {
        if (this.isCalendarLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isCalendarLoading = true;
        await this.clusterService
            .loadDaysData(PAGES.PROMOTION_DETECTION, this.hotelData.hotelId)
            .finally(() => {
                this.isCalendarLoading = false;
            });
    }

    async onCellClick(element: HTMLDivElement) {
        const day = Number(element.getAttribute('data-day'));

        const { hotelId } = this.hotelData;
        const { programView: program, provider } = this.clusterPromotionsService.settings;

        this.promotionsService
            .setDocument(this.hotelData.compsetMain);

        await this.$router.push({
            name: `${this.$route.name!}.popup`,
            params: {
                day: String(day),
                program: String(program),
                provider: String(provider),
                hotelId: String(hotelId),
                compsetId: String(this.compset?.id),
            },
        });
    }

    private registerCompset() {
        if (!this.hotelData.shownCompset) return;

        this.compsetsService
            .addCompset(this.hotelData.shownCompset);
    }
}
